<template>
	<v-container class="main-container">
		<p>Back</p>
		<h3 class="admin-default-subheader">Course Name</h3>
		<h2 class="admin-default-header">Course Settings</h2>
		<label class="admin-default-label" for="new_user_first_name"
			>Course Name</label
		>
		<v-text-field
			id="new_user_first_name"
			name="new_user_first_name"
			type="text"
			class="rounded-0 admin-default-input"
			solo
			rounded
			single-line
			v-model="newUserFirstName"
		></v-text-field>

		<label
			class="admin-default-label"
			for="organization_settings_home_subheader"
			>Description (Optional)</label
		>
		<v-textarea
			id="organization_settings_home_subheader"
			class="rounded-0 admin-default-textarea"
			:loading="isLoading"
			v-model="homePageSubheader"
		></v-textarea>
		<span class="admin-default-label mb-8">Main Image</span>
		<ImageFileInput
			:modelValue="$store.state.organization.homePageMainImage"
			:valueName="'homePageMainImage'"
			class="mb-16"
			:id="'organization_main_image'"
			:name="'organization_main_image'"
			:buttonLabel="'Upload New Image'"
		/>
		<v-btn class="admin-primary-button primary-contrast-background"
			>Done</v-btn
		>
	</v-container>
</template>

<script>
export default {
	name: "NewCourseCourseSettings"
};
</script>

<style></style>
